import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EmbeddedViewRef,
  HostBinding,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { NotificationsService } from '@box-core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements AfterViewInit, OnDestroy {
  private notificationsSubscription: Subscription;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private notificationsService: NotificationsService
  ) {}

  @HostBinding('class') public hostClass = 'notifications';
  @HostBinding('class.no-notifications') public noNotifications = true;

  ngAfterViewInit(): void {
    this.notificationsSubscription = this.notificationsService.notifications$.subscribe((notifications) => {
      this.noNotifications = notifications?.length === 0;
      for (const notification of notifications) {
        const domElem = (notification.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
        this.renderer.appendChild(this.element.nativeElement, domElem);
      }
    });
  }

  ngOnDestroy(): void {
    this.notificationsSubscription?.unsubscribe();
  }
}
