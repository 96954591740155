import { Injectable } from '@angular/core';
import {
  Coupon,
  Product,
  ProductInstance,
  PromoCampaign,
  Shop,
  ShopItemChange,
  ShopSuggestionBanner,
  APIError,
  Offer
} from '@box-types';
import {
  LoaderService,
  ShopService,
  DialogService,
  CartService,
  AnalyticsService,
  CouponsService,
  UserService,
  PromoCampaignsService,
  SentryService,
  AddressesService,
  ShopsService,
  CoreService
} from '@box-core/services';
import { ShopMenuDialogService } from '../services';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, of, map, Subscriber } from 'rxjs';
import {
  getShopSelectItemOfferGAConfig,
  getShopSelectItemProductGAConfig,
  offerHasOptions,
  createProductInstance,
  isEnabledByTimeRanges,
  normalizeOffer,
  normalizeProduct,
  getFirstOrderPromoCampaign,
  isLowOrderProbabilityUserWithCoupon,
  isProductPromoCampaign,
  promoCampaignHasImageKey,
  decorateOfferWithMaxItemsQuantity,
  decorateProductWithMaxItemsQuantity,
  getSuggestedProducts,
  PromoCampaignsItems,
  sortAvailableCoupons,
  checkMerchantSponsoredCampaignEligibility,
  getClosestItemToAddress,
  getPathWithoutQueryParams,
  getLastPathSegment
} from '@box/utils';
import { ShopPageService, OffersService, ProductsService } from '@box-delivery/services';
import { Router } from '@angular/router';

@Injectable()
export class ShopMenuPageService {
  constructor(
    private cartService: CartService,
    private shopService: ShopService,
    private offersService: OffersService,
    private productsService: ProductsService,
    private shopMenuDialogService: ShopMenuDialogService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private shopPageService: ShopPageService,
    private analyticsService: AnalyticsService,
    private promoCampaignsService: PromoCampaignsService,
    private sentryService: SentryService,
    private couponsService: CouponsService,
    private userService: UserService,
    private addressesService: AddressesService,
    private shopsService: ShopsService,
    private router: Router,
    private coreService: CoreService
  ) {}

  public getProductAndOpenMyo(productId: string): void {
    const { collectionType, supermarketGroup } = this.shopService.getShop();
    const options = { collectionType, supermarketGroup };
    this.productsService.fetchProduct(productId, options).subscribe({
      next: (product) => {
        const shopItems = this.shopService.getShopItemsFromMemory();
        shopItems.products = [product, ...shopItems.products];
        const decoratedProducts = this.shopPageService.generateShopMenuProducts(this.shopService.getShop(), shopItems);
        this.openProductMYOCheck(productId, decoratedProducts);
      },
      error: (error: APIError) => this.dialogService.openErrorDialog(error)
    });
  }

  public getOfferAndOpenMyo(offerId: string): void {
    const { collectionType, supermarketGroup } = this.shopService.getShop();
    const options = { collectionType, supermarketGroup };
    this.offersService.fetchOffer(offerId, options).subscribe({
      next: (offer) => {
        const shopItems = this.shopService.getShopItemsFromMemory();
        shopItems.offers = [offer, ...shopItems.offers];
        const decoratedOffers = this.shopPageService.generateShopMenuOffers(this.shopService.getShop(), shopItems);
        this.openOfferWizardCheck(offerId, decoratedOffers);
      },
      error: (error: APIError) => this.dialogService.openErrorDialog(error)
    });
  }

  public onProductSelect(data: ShopItemChange<Product>): void {
    const hasAddress = this.addressesService.getAddress();
    if (!hasAddress) return this.handleUserWithoutAddress();
    if (!this.shopService.getShop().found) return this.shopPageService.openUnreachableAddressDelivery();

    const { item: product } = data;

    const existsWithSelectionsInCart: boolean = this.shopService.cartProductWithSelectionsExists(product);
    if (existsWithSelectionsInCart) return this.shopMenuDialogService.openSameMYODialog(product, 'product');
    this.triggerAnalyticsSelectProductEvent(product);
    this.shopMenuDialogService.openProductMYO(product);
  }

  public onProductAdd(data: ShopItemChange<Product>): void {
    const hasAddress = this.addressesService.getAddress();
    if (!hasAddress) return this.handleUserWithoutAddress();
    if (!this.shopService.getShop().found) return this.shopPageService.openUnreachableAddressDelivery();

    const { item: product } = data;
    const existsWithSelectionsInCart: boolean = this.shopService.cartProductWithSelectionsExists(product);
    if (existsWithSelectionsInCart) return this.shopMenuDialogService.openSameMYODialog(product, 'product');

    const hasSelections = product.selections && product.selections.length > 0;
    if (hasSelections) return this.shopMenuDialogService.openProductMYO(product);

    const itemInstance: ProductInstance = createProductInstance(product);
    if (!itemInstance.hasCustomPrice) {
      return this.addProductToCart(product, itemInstance);
    }

    // For pizza fan products in case our db has not been updated.
    this.loaderService.setState(true);
    this.shopService
      .getDynamicPrice(product._id, itemInstance)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (price) => {
          const updatedProductInstance: ProductInstance = { ...itemInstance, price: price, basePrice: price };
          this.addProductToCart(product, updatedProductInstance);
        },
        error: (error: APIError) => {
          this.shopMenuDialogService.showProductInfoDialog({
            title: error.userTitle,
            messages: [error.userMessage]
          });
          return of(null);
        }
      });
  }

  public onOfferSelect(data: ShopItemChange<Offer>): void {
    const hasAddress = this.addressesService.getAddress();
    if (!hasAddress) return this.handleUserWithoutAddress();
    if (!this.shopService.getShop().found) return this.shopPageService.openUnreachableAddressDelivery();

    const { item: offer } = data;

    this.checkDFYAndShowDialog(offer);

    const hasOptions: boolean = offerHasOptions(offer);
    const existsInCart: boolean = this.shopService.cartOfferExists(offer);
    if (existsInCart && hasOptions) return this.shopMenuDialogService.openSameMYODialog(offer, 'offer');
    this.triggerAnalyticsSelectOfferEvent(offer);
    return this.shopMenuDialogService.openOfferWizard(offer);
  }

  public onOfferAdd(data: ShopItemChange<Offer>): void {
    const hasAddress = this.addressesService.getAddress();
    if (!hasAddress) return this.handleUserWithoutAddress();
    if (!this.shopService.getShop().found) return this.shopPageService.openUnreachableAddressDelivery();

    const { item: offer } = data;
    this.checkDFYAndShowDialog(offer);

    const existsInCart: boolean = this.shopService.cartOfferExists(offer);
    const hasOptions: boolean = offerHasOptions(offer);
    if (existsInCart && hasOptions) return this.shopMenuDialogService.openSameMYODialog(offer, 'offer');
    return this.shopMenuDialogService.openOfferWizard(offer);
  }

  private checkDFYAndShowDialog(offer: Offer) {
    const cartHasDFY: boolean = this.shopService.cartHasDFYOffer();
    if (offer.isDFY && cartHasDFY) return this.shopMenuDialogService.openDFYWarningDialog(offer);
  }

  public openProductMYOCheck(productId: string, products?: Product[]): void {
    if (!productId) return;
    const finalProducts: Product[] = products?.length ? products : [...this.shopService.menuProducts.getValue()];
    const product: Product = finalProducts.find((p) => p._id === productId);
    if (!product) return;
    return this.shopMenuDialogService.openProductMYO(product);
  }

  public openOfferWizardCheck(offerId: string, offers?: Offer[]): void {
    if (!offerId) return;
    const finalOffers: Offer[] = offers?.length ? offers : [...this.shopService.menuOffers.getValue()];
    const offer: Offer = finalOffers.find((o) => o._id === offerId);
    if (!offer) return;
    return this.shopMenuDialogService.openOfferWizard(offer);
  }

  public onProductRemove(data: ShopItemChange<Product>): void {
    const { item: product } = data;
    const existsWithSelectionsInCart: boolean = this.shopService.cartProductWithSelectionsExists(product);
    if (existsWithSelectionsInCart) return this.shopMenuDialogService.openSameMYODialog(product, 'product');
    const itemInstance: ProductInstance = createProductInstance(product);
    const cartResponse = this.cartService.removeProduct(product, itemInstance);
    if (cartResponse === 'PRODUCT_REMOVED') this.shopService.syncCartProductToMenu(product);
  }

  public onOfferRemove(data: ShopItemChange<Offer>): void {
    const { item: offer } = data;
    const existsInCart: boolean = this.shopService.cartOfferExists(offer);
    if (existsInCart) return this.shopMenuDialogService.openSameMYODialog(offer, 'offer');
  }

  private addProductToCart(product: Product, instance: ProductInstance): void {
    const cartResponse = this.cartService.addProduct(product, instance);
    if (cartResponse === 'PRODUCT_ADDED') {
      this.shopService.syncCartProductToMenu(product);
      this.shopService.addToCartProductAnalyticsEvent(product, instance, 'shop');
    }
  }

  private triggerAnalyticsSelectProductEvent(product: Product): void {
    const shop = this.shopService.getShop();
    const gaConfig = getShopSelectItemProductGAConfig(product, shop);
    this.analyticsService.addGAEcommerceEvent('select_item', gaConfig);
  }

  private triggerAnalyticsSelectOfferEvent(offer: Offer): void {
    const shop = this.shopService.getShop();
    const gaConfig = getShopSelectItemOfferGAConfig(offer, shop);
    this.analyticsService.addGAEcommerceEvent('select_item', gaConfig);
  }

  private getShopMenuPromoCampaigns(shop: Shop): PromoCampaign[] {
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    return promoCampaigns.filter(
      (promoCampaign) =>
        shop.promoCampaigns.includes(promoCampaign.name) &&
        promoCampaign.visibleInShopMenu &&
        promoCampaignHasImageKey(promoCampaign, 'inShopPlacementImage')
    );
  }

  private promoCampaignsItemsResponseToSuggestionBanner(
    response: PromoCampaignsItems,
    promoCampaign: PromoCampaign
  ): ShopSuggestionBanner {
    const shop = this.shopService.getShop();
    if (isProductPromoCampaign(promoCampaign)) {
      const responseOffers = response?.offers ?? [];
      const responseProducts = response?.products ?? [];
      const normalizedResponseOffers = responseOffers.map((offer) => normalizeOffer(offer));
      const normalizedResponseProducts = responseProducts.map((product) => normalizeProduct(product));
      const usersFrequentPlates = this.shopService.usersFrequentPlates.getValue();
      const suggestedProducts = getSuggestedProducts(normalizedResponseProducts, usersFrequentPlates);
      const suggestedProductsIds = suggestedProducts.map((product) => product._id);

      const decoratedOffers = normalizedResponseOffers.map((offer) => {
        const decoratedOffer = this.shopService.offerDecorator(offer, suggestedProductsIds);
        return decorateOfferWithMaxItemsQuantity(decoratedOffer, shop);
      });

      const decoratedProducts = normalizedResponseProducts.map((product) => {
        const decoratedProduct = this.shopService.decorateProduct(product);
        return decorateProductWithMaxItemsQuantity(decoratedProduct, shop);
      });

      const suggestionBanner = this.promoCampaignsService.promoCampaignToShopSuggestionBanner(promoCampaign, shop);
      return { ...suggestionBanner, offers: decoratedOffers, products: decoratedProducts };
    }
    return this.promoCampaignsService.promoCampaignToShopSuggestionBanner(promoCampaign, shop);
  }

  public getShopSuggestionCoupons(shop: Shop): Observable<Coupon[]> {
    this.loaderService.setState(true);
    return this.couponsService.fetchShopCoupons(shop.collectionType).pipe(
      finalize(() => this.loaderService.setState(false)),
      map((coupons) => sortAvailableCoupons(coupons)),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Coupons',
          domainDetails: 'Shop Coupons Available',
          severity: 'error'
        });
        return of([]);
      })
    );
  }

  public getShopSuggestionBanners(shop: Shop): Observable<ShopSuggestionBanner[]> {
    return new Observable((subscriber: Subscriber<ShopSuggestionBanner[]>) => {
      const shopPromoCampaigns = this.getShopMenuPromoCampaigns(shop);
      const firstPromoCampaignsGroup: PromoCampaign[] = [];
      firstPromoCampaignsGroup.push(...shopPromoCampaigns);

      const firstOrderPromoCampaign = getFirstOrderPromoCampaign(shop, shopPromoCampaigns);
      if (firstOrderPromoCampaign) firstPromoCampaignsGroup.push(firstOrderPromoCampaign);

      const merchantPromoCampaigns = firstPromoCampaignsGroup
        .filter((pc) => checkMerchantSponsoredCampaignEligibility(shop, pc))
        .sort((a, b) => b.multiplier - a.multiplier);

      const priorityPromoCampaignIndex = merchantPromoCampaigns.findIndex((promoCampaign) =>
        isEnabledByTimeRanges(promoCampaign.enabledOnTimeRanges)
      );
      const priorityPromoCampaign = merchantPromoCampaigns[priorityPromoCampaignIndex];
      /*
            We find the first enabled Merchant Sponsored Campaign in order (Multiplier Sorted), and we place it with the PromoCampaigns
            that will be sorted by priority. The Merchant Promo Campaigns until that point, are gonna be added at the end of the list
            In the end, we discard the remaining Merchant Sponsored Campaigns after the first enabled Merchant Sponsored Campaign
          */
      const remainingPromoCampaigns =
        priorityPromoCampaignIndex === -1
          ? merchantPromoCampaigns
          : merchantPromoCampaigns.slice(0, priorityPromoCampaignIndex);
      const regularPromoCampaigns = shopPromoCampaigns.filter((pc) => pc.type !== 'merchant_sponsored');
      const priorityPromoCampaigns: PromoCampaign[] = [];
      if (priorityPromoCampaign) priorityPromoCampaigns.push(priorityPromoCampaign);
      priorityPromoCampaigns.push(...regularPromoCampaigns);

      const hasExploreCoupon = isLowOrderProbabilityUserWithCoupon(this.userService.getUser());
      const eligiblePromoCampaigns = [...priorityPromoCampaigns, ...remainingPromoCampaigns].filter((promoCampaign) => {
        if (!hasExploreCoupon) return true;
        if (promoCampaign.name === 'new_users') return false;
        return true;
      });

      if (eligiblePromoCampaigns.length === 0) {
        const suggestionBanners: ShopSuggestionBanner[] = [];
        subscriber.next(suggestionBanners);
        return subscriber.complete();
      }

      const { collectionType, supermarketGroup } = shop;
      const options = {
        promoCampaigns: eligiblePromoCampaigns.map((promoCampaign) => promoCampaign._id),
        supermarketGroup
      };

      this.promoCampaignsService
        .fetchPromoCampaignsItems(collectionType, options)
        .pipe(
          map((response) => {
            const responsePromoCampaigns = response.campaigns.filter((responseCampaign) => {
              const promoCampaign = shopPromoCampaigns.find((pc) => pc.name === responseCampaign.name);
              if (!isProductPromoCampaign(promoCampaign)) return true;
              const hasOffers = responseCampaign.offers.length > 0;
              const hasProducts = responseCampaign.products.length > 0;
              if (hasOffers || hasProducts) return true;
              return false;
            });

            const prioritySuggestionBanners = priorityPromoCampaigns.map((promoCampaign) => {
              const promoCampaignItems = responsePromoCampaigns.find((rpc) => rpc.name === promoCampaign.name);
              return this.promoCampaignsItemsResponseToSuggestionBanner(promoCampaignItems, promoCampaign);
            });

            const remainingSuggestionBanners = remainingPromoCampaigns.map((promoCampaign) => {
              const promoCampaignItems = responsePromoCampaigns.find((rpc) => rpc.name === promoCampaign.name);
              return this.promoCampaignsItemsResponseToSuggestionBanner(promoCampaignItems, promoCampaign);
            });

            const suggestionBanners: ShopSuggestionBanner[] = [];
            suggestionBanners.push(...prioritySuggestionBanners.sort((a, b) => b.priority - a.priority));
            suggestionBanners.push(...remainingSuggestionBanners);

            return suggestionBanners.filter((suggestionBanner) => {
              if (!hasExploreCoupon) return true;
              if (suggestionBanner.campaignName === 'new_users') return false;
              return true;
            });
          })
        )
        .subscribe({
          next: (banners) => subscriber.next(banners),
          error: (error: Error | APIError) => subscriber.error(error),
          complete: () => subscriber.complete()
        });
    }).pipe(
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Shop Page',
          domainDetails: 'Shop Suggestion Banners',
          severity: 'error'
        });
        return of([]);
      })
    );
  }

  public handleUserWithoutAddress(): void {
    this.addressesService.initiateAddSmallAddressDialogFlow$().subscribe((response) => {
      if (!response?.address) return;
      if (!this.shopService.getShop().chainView) return;
      const pathWithoutParams = getPathWithoutQueryParams(this.router.url);
      const chainSlug = getLastPathSegment(pathWithoutParams);
      const coreChains = this.coreService.chains.getValue();
      const chainKey = coreChains.find((chain) => chain.slug === chainSlug)?.chainKey;
      if (!chainKey) return this.router.navigate(['/']);
      this.loaderService.setState(true);
      this.shopsService
        .getShopsByChainKey(chainKey)
        .pipe(finalize(() => this.loaderService.setState(false)))
        .subscribe((shops) => {
          const address = response.address;
          const closestShop = getClosestItemToAddress(address, shops);
          if (!closestShop) return this.router.navigate(['/']);
          this.router.navigate(['/delivery', closestShop.locationKey, closestShop.vanityUrl]);
        });
    });
  }
}
