import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DiscoverFilter, PromoVisualOptions } from '@box-types';
import { getDiscoverFilterMultiplierTag, generateImageSrc } from '@box/utils';

@Component({
  selector: 'home-discover-filter',
  templateUrl: 'home-discover-filter.component.html',
  styleUrls: ['home-discover-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeDiscoverFilterComponent implements OnChanges {
  @Input() public discoverFilter: DiscoverFilter;

  public tags: PromoVisualOptions[];
  public imageUrl: string;
  public altText: string;

  @HostBinding('class') public hostClass = 'home-discover-filter';

  ngOnChanges(changes: SimpleChanges): void {
    this.discoverFilter = changes.discoverFilter.currentValue as DiscoverFilter;
    this.imageUrl = generateImageSrc(this.discoverFilter.mainViewWebImage);
    this.altText = this.discoverFilter.title;
    this.tags = getDiscoverFilterMultiplierTag(this.discoverFilter);
  }
}
