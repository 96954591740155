import { Component, OnInit } from '@angular/core';
import { NotificationConfig, NotificationRef } from '@box-core/components';
import { DaasAvailability } from '@box-types';

@Component({
  selector: 'daas-delay',
  templateUrl: './daas-delay.component.html',
  styleUrls: ['./daas-delay.component.scss']
})
export class DaasDelayComponent implements OnInit {
  public message: string;

  constructor(private notificationConfig: NotificationConfig, private notificationRef: NotificationRef) {}

  ngOnInit(): void {
    const availability = (this.notificationConfig.data as { availability: DaasAvailability }).availability;
    this.message = availability.message;
  }

  public onClose(): void {
    this.notificationRef.dismiss();
  }
}
