import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Shop, Timeslot, APIError, DaasAvailabilityFetchOptions, GAClickDeliveryTimeBannerConfig } from '@box-types';
import { Subscription } from 'rxjs';
import { TimeslotsService } from '@box-core/services/timeslots.service';
import {
  getNextTimeslot,
  daasAvailabilityHasNoTimeslots,
  isDaasAvailabilityScheduled,
  shouldFetchDaasAvailability
} from '@box/utils';
import { DaasAvailabilityService } from '@box-core/services/daas-availability.service';
import { AddressesService, AnalyticsService, DialogService, LoaderService } from '@box-core/services';
import { finalize, tap } from 'rxjs/operators';
import { TimeslotsDialogsService } from '@box-core/services/timeslots-dialogs.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CheckoutTimeslotService } from '@box-checkout/components/checkout-timeslot/checkout-timeslot.service';

@Component({
  selector: 'checkout-timeslot',
  templateUrl: './checkout-timeslot.component.html',
  styleUrls: ['./checkout-timeslot.component.scss'],
  providers: [CheckoutTimeslotService]
})
export class CheckoutTimeslotComponent implements OnInit, OnDestroy {
  @ViewChild('timeslotsPanel') private timeslotsPanel: MatExpansionPanel;
  @Input() private shop: Shop;
  @Input() private checkDaasAvailability: boolean;

  public icon: string;
  public timeslotText: string;
  public nextAvailableTimeslot: Timeslot;

  private timeslots: Timeslot[];
  private timeslotSubscription: Subscription;

  constructor(
    private timeslotsService: TimeslotsService,
    private timeslotDialogsService: TimeslotsDialogsService,
    private daasAvailabilityService: DaasAvailabilityService,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private addressesService: AddressesService,
    private checkoutTimeslotService: CheckoutTimeslotService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.timeslots = this.timeslotsService.getTimeslots();
    if (this.shop.isSuperMarket) this.nextAvailableTimeslot = getNextTimeslot(this.timeslots);
    this.setTimeslotSubscription();
  }

  ngOnDestroy(): void {
    if (this.timeslotSubscription) this.timeslotSubscription.unsubscribe();
    this.timeslotsService.clearTimeslot();
    this.timeslotsService.clearTimeslots();
    this.daasAvailabilityService.removeNotification();
  }

  public onEdit(): void {
    if (!this.shop.timeslots?.length) {
      return this.timeslotDialogsService.showCheckoutNoTimeslotSupportDialog(this.shop);
    }
    this.timeslotDialogsService
      .openTimeslotsDialog(this.shop, this.nextAvailableTimeslot)
      .afterClosed()
      .subscribe(() => {
        this.timeslotsPanel.close();
        this.triggerAnalyticsEvent();
      });
  }

  private setTimeslotView(): void {
    this.icon = this.checkoutTimeslotService.getTimeslotMatIcon();
    this.timeslotText = this.checkoutTimeslotService.generateTimeslotText();
  }

  private setTimeslotSubscription(): void {
    this.timeslotSubscription = this.timeslotsService.timeslot$.subscribe((timeslot) => {
      this.setTimeslotView();
      this.checkoutTimeslotService.checkTimeslotAvailability();
      const address = this.addressesService.getAddress();
      if (shouldFetchDaasAvailability(this.shop, address, timeslot) && this.checkDaasAvailability) {
        this.getDaasAvailability();
      }
    });
  }

  private getDaasAvailability(): void {
    this.loaderService.setState(true);
    const address = this.addressesService.getAddress();
    const daasAvailabilityOptions: DaasAvailabilityFetchOptions = {
      origin: 'checkout',
      deliveryETA: this.shop.projectedDeliveryETA,
      latitude: address?.latitude,
      longitude: address?.longitude
    };

    this.daasAvailabilityService
      .fetchDaasAvailability(this.shop, daasAvailabilityOptions)
      .pipe(
        tap((availability) => this.daasAvailabilityService.postFetchActions(this.shop, availability)),
        finalize(() => this.loaderService.setState(false))
      )
      .subscribe({
        next: (availability) => {
          if (daasAvailabilityHasNoTimeslots(availability)) {
            this.daasAvailabilityService.showUnavailableTimeslotsDialog();
          }

          if (isDaasAvailabilityScheduled(availability)) {
            this.nextAvailableTimeslot = availability.firstAvailableTimeslot;
            this.timeslotsService.setTimeslot(availability.firstAvailableTimeslot);
            this.checkoutTimeslotService.setFutureTimeslots(availability.firstAvailableTimeslot);
          }
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private triggerAnalyticsEvent(): void {
    const timeOption = this.icon === 'schedule' ? 'asap' : 'scheduled';
    const gaConfig = { time_option: timeOption } as GAClickDeliveryTimeBannerConfig;
    this.analyticsService.addGACustomEvent('click_delivery_time_banner', gaConfig);
  }
}
