import { Component, OnInit, Inject, Renderer2, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsService, PurchaseEventService } from '@box-core/services';
import { Shop, PromoBanner, PromoCoupon, GACloseModalConfig, GAPromotionConfig } from '@box-types';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { getCollectionShopsImpressionGAConfig, generateImageSrc } from '@box/utils';
import { PromoBannerDetailsDialogService } from './promo-banner-details-dialog.service';
import { PromoBannerDetailsDialogData, PromoBannerDetailsDialogResponse } from './promo-banner-details-dialog.types';

@Component({
  selector: 'promo-banner-details-dialog',
  templateUrl: './promo-banner-details-dialog.component.html',
  styleUrls: ['./promo-banner-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PromoBannerDetailsDialogService]
})
export class PromoBannerDetailsDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public title: string;
  public imageUrl: string;
  public buttonText: string;
  public description: string;
  public coupon: PromoCoupon;
  public relatedShops: Shop[];
  public readonly DEFAULT_INTERSECTION_ROOT_MARGIN = '0px 0px 12px 0px';
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 1.0;

  private promoBanner: PromoBanner;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<PromoBannerDetailsDialogComponent>,
    private promoBannerDetailsDialogService: PromoBannerDetailsDialogService,
    private analyticsService: AnalyticsService,
    private purchaseEventService: PurchaseEventService,
    @Inject(MAT_DIALOG_DATA) private data: PromoBannerDetailsDialogData
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.promoBanner = this.data.promoBanner;
    this.title = this.promoBanner.detailsTitle;
    this.description = this.promoBannerDetailsDialogService.getPromoBannerBlocksHtml(this.promoBanner);
    this.buttonText = this.promoBanner.buttonTitle;
    this.imageUrl = generateImageSrc(this.promoBanner.detailsWebImage);
    this.coupon = this.promoBanner.coupon;
    this.relatedShops = this.promoBanner.relatedShops;
  }

  public onAction(): void {
    this.closeDialog({ triggerAction: true });
  }

  public onShopCardClick(shop: Shop): void {
    this.triggerAnalyticsSelectPromoEvent(shop);
    this.closeDialog({ triggerAction: false });
  }

  public onCloseClick(): void {
    this.closeDialog();
    this.triggerButtonAnalyticsEvents();
  }

  public onShopEnteredViewport(shop: Shop): void {
    this.triggerAnalyticsViewPromoEvent(shop);
  }

  private closeDialog(data?: PromoBannerDetailsDialogResponse): void {
    this.dialogRef.close(data);
  }

  private triggerButtonAnalyticsEvents(): void {
    const gaConfig = {
      creative_name: this.promoBanner.slug,
      creative_slot: 'campaign modal',
      promotion_id: '',
      promotion_name: this.promoBanner.title
    } as GACloseModalConfig;
    this.analyticsService.addGACustomEvent('close_modal', gaConfig);
  }

  private triggerAnalyticsViewPromoEvent(shop: Shop): void {
    const gaConfig = getCollectionShopsImpressionGAConfig(this.promoBanner, this.relatedShops, shop);
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerAnalyticsSelectPromoEvent(shop: Shop): void {
    const gaConfig = getCollectionShopsImpressionGAConfig(this.promoBanner, this.relatedShops, shop);
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
    this.updatePurchaseEvent(shop, gaConfig);
  }

  private updatePurchaseEvent(shop: Shop, gaConfig: GAPromotionConfig) {
    if (!gaConfig?.items?.length) return;
    const shopIndex = gaConfig['items'][0].index;
    const purchaseEvent = {
      creativeName: this.promoBanner.slug,
      index: shopIndex,
      itemListName: 'campaign modal',
      creativeSlot: 'campaign modal',
      promotionName: this.promoBanner.title
    };
    this.purchaseEventService.updatePurchaseEvent(shop._id, purchaseEvent);
  }
}
