import { Component, ChangeDetectionStrategy, HostBinding, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ShopTagSlide } from '@box-core/animations/shops.animations';
import { Shop, PromoVisualOptions } from '@box-types';
import { canShowShopRatings, generateImageSrc } from '@box/utils';

@Component({
  selector: 'shop-tile-detailed',
  templateUrl: './shop-tile-detailed.component.html',
  styleUrls: ['./shop-tile-detailed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ShopTagSlide]
})
export class ShopTileDetailedComponent implements OnChanges {
  @Input() public shop: Shop;
  @Input() public disableAnimation = false;

  public name: string;
  public cuisineText: string;
  public logoUrl: string;
  public backgroundImageUrl: string;
  public isNew: boolean;
  public routerLink: string;
  public showRating: boolean;
  public firstGroupTagOptions: PromoVisualOptions;
  public secondGroupTagOptions: PromoVisualOptions;
  public showTags: boolean;

  @HostBinding('class') public hostClass = 'shop-tile-detailed';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableAnimation) this.disableAnimation = changes.disableAnimation.currentValue as boolean;
    if (changes.shop) {
      this.shop = changes.shop.currentValue as Shop;
      this.name = this.shop.name;
      this.cuisineText = this.shop.mainCuisineText;
      this.logoUrl = generateImageSrc(this.shop.logo);
      this.backgroundImageUrl = generateImageSrc(this.shop.mobileImage);
      this.isNew = this.shop.isNew;
      this.routerLink = `/delivery/${this.shop.locationKey}/${this.shop.vanity_url}`;
      this.showRating = canShowShopRatings(this.shop.ratingCounter);
      this.firstGroupTagOptions = this.shop.promo?.firstGroupLongOptions;
      this.secondGroupTagOptions = this.shop.promo?.secondGroupLongOptions;
      this.showTags = Boolean(this.firstGroupTagOptions) || Boolean(this.secondGroupTagOptions);
    }
  }
}
