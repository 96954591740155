import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { PromoVisualOptions } from '@box-types';

@Component({
  selector: 'promo-badge',
  templateUrl: './promo-badge.component.html',
  styleUrls: ['./promo-badge.component.scss']
})
export class PromoBadgeComponent implements OnInit {
  @Input() private options: PromoVisualOptions;

  public text: string;
  public image: string;
  public textBadgeStyles: unknown;

  @HostBinding('class') public hostClass = 'promo-badge';

  ngOnInit(): void {
    this.setStateContent(this.options);
  }

  private setStateContent(options: PromoVisualOptions): void {
    this.text = options.text;
    this.image = options.image;
    this.textBadgeStyles = { color: options.colors?.foreground, backgroundColor: options.colors?.background };
  }
}
