<a class="shop-tile-detailed-link" [routerLink]="routerLink">
  <div class="shop-tile-detailed-header">
    <div *ngIf="showTags" class="shop-tile-detailed-header-tags">
      <promo-tag
        [@shopTagSlide]
        [@.disabled]="disableAnimation"
        *ngIf="firstGroupTagOptions"
        [options]="firstGroupTagOptions"
      ></promo-tag>
      <promo-tag
        [@shopTagSlide]
        [@.disabled]="disableAnimation"
        *ngIf="secondGroupTagOptions"
        [options]="secondGroupTagOptions"
      ></promo-tag>
    </div>
    <image-tile class="shop-tile-detailed-header-logo" [imgUrl]="logoUrl" [altText]="name + ' logo'"> </image-tile>
    <image-tile class="shop-tile-detailed-header-background-image" [imgUrl]="backgroundImageUrl"></image-tile>
  </div>
  <div class="shop-tile-detailed-details">
    <div class="shop-tile-detailed-details-row">
      <span [title]="name" class="shop-tile-detailed-details-name">{{ name }}</span>
      <shop-rating [shop]="shop"></shop-rating>
    </div>
    <div class="shop-tile-detailed-details-row">
      <shop-tile-delivery-info [shop]="shop"></shop-tile-delivery-info>
      <span *ngIf="isNew" class="shop-tile-detailed-details-new-tag">NEW</span>
    </div>
  </div>
</a>
