<div class="swiper-section box-swiper">
  <div class="swiper-section-header">
    <div class="swiper-section-header-content">
      <h3 class="swiper-section-title">{{ title }}</h3>
    </div>
    <div #navButtonContainer class="swiper-section-navigation">
      <button #prevBtn mat-icon-button class="box-swiper-button-prev">
        <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
      </button>
      <button #nextBtn mat-icon-button class="box-swiper-button-next">
        <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div [swiper]="swiperOptions" *ngIf="mainViewDiscoverFilters?.length > 0">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let discoverFilter of mainViewDiscoverFilters">
        <home-discover-filter
          visibilityObserver
          [triggerOnce]="true"
          [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
          (enteredViewport)="onDiscoverFilterEnteredViewport(discoverFilter)"
          [discoverFilter]="discoverFilter"
          (click)="onDiscoverFilterClick(discoverFilter)"
        >
        </home-discover-filter>
      </div>
    </div>
  </div>
</div>
