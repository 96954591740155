import { Injectable } from '@angular/core';
import { UserService } from '@box-core/services/user.service';
import { ShopService } from '@box-core/services/shop.service';
import { PaymentTypesService } from '@box-core/services/payment-types.service';
import { PromoCampaignsService } from '@box-core/services/promo-campaigns.service';
import { CartService } from '@box-core/services/cart.service';
import { PromoCampaign } from '@box-types';
import {
  filterPromoCampaignsByType,
  getBoxOfferPromoCampaigns,
  promoCampaignHasTextKey,
  promoCampaignHasImageKey,
  getCampaignsCompatibleWithPaymentType
} from '@box/utils';
import { Router } from '@angular/router';

@Injectable()
export class CampaignEligibilityService {
  constructor(
    private userService: UserService,
    private shopService: ShopService,
    private cartService: CartService,
    private paymentTypesService: PaymentTypesService,
    private promoCampaignsService: PromoCampaignsService,
    private router: Router
  ) {}

  private getMerchantSponsoredPromoCampaign(promoCampaigns: PromoCampaign[]): PromoCampaign {
    const shop = this.shopService.getShop();
    if (!shop.promo?.bestMerchantSponsoredPromoCampaignName) return;
    const merchantPromoCampaigns = filterPromoCampaignsByType(promoCampaigns, 'merchant_sponsored');
    return merchantPromoCampaigns.find((c) => shop.promo.bestMerchantSponsoredPromoCampaignName === c.name);
  }

  private getCartProductPromoCampaigns(promoCampaigns: PromoCampaign[]): PromoCampaign[] {
    const productCampaigns = [
      ...filterPromoCampaignsByType(promoCampaigns, 'product_promo'),
      ...filterPromoCampaignsByType(promoCampaigns, 'product_cuisine_promo'),
      ...filterPromoCampaignsByType(promoCampaigns, 'collection_drink')
    ];
    return productCampaigns.filter((c) => this.cartService.cartHasPromoCampaign(c));
  }

  public getFirstOrderPromoCampaigns(promoCampaigns: PromoCampaign[]): PromoCampaign[] {
    if (!this.userService.isNewUser()) return [];
    const businessVertical = this.shopService.getShop().businessVertical;
    return promoCampaigns
      .filter((campaign) => campaign.type === 'first_order')
      .filter((campaign) => {
        if (!campaign.businessVertical) return campaign;
        if (campaign.businessVertical === businessVertical) return campaign;
      });
  }

  public getConsumablePromoCampaigns(): PromoCampaign[] {
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const consumableTypes = [
      'box_offer',
      'payment',
      'merchant_sponsored',
      'collection_drink',
      'product_promo',
      'product_cuisine_promo',
      'first_order'
    ];
    return promoCampaigns.filter((c) => consumableTypes.includes(c.type));
  }

  public getPromoCampaignsForSelectedPaymentType(promoCampaigns?: PromoCampaign[]): PromoCampaign[] {
    const shopBusinessVertical = this.shopService.getShop().businessVertical;
    const topPaymentCampaigns = this.promoCampaignsService.getTopPaymentCampaigns(shopBusinessVertical, promoCampaigns);
    const paymentType = this.paymentTypesService.getPaymentType();
    return getCampaignsCompatibleWithPaymentType(paymentType, topPaymentCampaigns);
  }

  public getConsumedPromoCampaigns(): PromoCampaign[] {
    const isInCheckoutPage = this.router.url.includes('/checkout');
    const consumablePromoCampaigns = this.getConsumablePromoCampaigns();

    const firstOrderPromoCampaigns = isInCheckoutPage ? this.getFirstOrderPromoCampaigns(consumablePromoCampaigns) : [];
    const paymentPromoCampaigns = isInCheckoutPage
      ? this.getPromoCampaignsForSelectedPaymentType(consumablePromoCampaigns)
      : [];

    const boxOfferPromoCampaigns = getBoxOfferPromoCampaigns(consumablePromoCampaigns);
    const merchantSponsoredPromoCampaign = this.getMerchantSponsoredPromoCampaign(consumablePromoCampaigns);
    const productPromoCampaigns = this.getCartProductPromoCampaigns(consumablePromoCampaigns);

    const consumedPromoCampaigns = [
      ...boxOfferPromoCampaigns,
      ...paymentPromoCampaigns,
      merchantSponsoredPromoCampaign,
      ...productPromoCampaigns,
      ...firstOrderPromoCampaigns
    ];

    const shop = this.shopService.getShop();
    const activePromoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const consumablePromoCampaignsIds = consumablePromoCampaigns.map((campaign) => campaign._id);
    const remainingPromoCampaigns = activePromoCampaigns.filter(
      (campaign) =>
        !consumablePromoCampaignsIds.includes(campaign._id) &&
        promoCampaignHasTextKey(campaign, 'name') &&
        promoCampaignHasImageKey(campaign, 'checkoutBannerLogo') &&
        shop.promo.activePromoCampaignsNames.includes(campaign.name)
    );

    return [...consumedPromoCampaigns, ...remainingPromoCampaigns].filter(Boolean);
  }
}
