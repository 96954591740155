import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { DiscoverFilter, Shop, APIError, APIResponse, BusinessVertical } from '@box-types';
import {
  filterEnabledItems,
  filterHasShopResults,
  filterShopsByDiscoverFilters,
  getDiscoverFilterMultiplier,
  isFilterDishRelated,
  getMainViewTileCurrentPosition,
  isTimestampExpired
} from '@box/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import dayjs from 'dayjs';
import defaultDiscoverFilters from '@box-data/default-discover-filters.json';
import {
  SentryService,
  ConfigurationService,
  CoreService,
  OrdersService,
  PromoCampaignsService
} from '@box-core/services';
import orderBy from 'lodash-es/orderBy';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class DiscoverFiltersService {
  private DISCOVER_FILTERS_SESSION_EXPIRATION = 10 * 60 * 1000;
  private BOX_API: string = environment.application.API_URL;
  private discoverFiltersTimestamp: number;
  /** The Discover Fitlers State management should be delegated to the appropriate lower level services
   * and or components that use it. This is a global service due to the fact that we need the filter
   * on Main and Discover Page.
   */
  private readonly apiDiscoverFiltersSource = new BehaviorSubject<DiscoverFilter[]>([]);
  private readonly discoverFiltersSource = new BehaviorSubject<DiscoverFilter[]>([]);

  public readonly apiDiscoverFilters$ = this.apiDiscoverFiltersSource.asObservable();
  public readonly discoverFilters$ = this.discoverFiltersSource.asObservable();

  constructor(
    private http: HttpClient,
    private ordersService: OrdersService,
    private coreService: CoreService,
    private promoCampaignsService: PromoCampaignsService,
    private configService: ConfigurationService,
    private sentryService: SentryService,
    private userService: UserService
  ) {}

  public getDiscoverFilters(): DiscoverFilter[] {
    return this.discoverFiltersSource.getValue();
  }

  public setDiscoverFilters(discoverFilters: DiscoverFilter[]): void {
    this.discoverFiltersSource.next(discoverFilters);
  }

  public getCheckedDiscoverFilters$(): Observable<DiscoverFilter[]> {
    return this.discoverFilters$.pipe(
      map((filters) => {
        return filters.filter((filter) => filter.active);
      })
    );
  }

  public getCheckedDiscoverFilters(): DiscoverFilter[] {
    return this.getDiscoverFilters().filter((filter) => filter.active);
  }

  public getAPIDiscoverFilters(): DiscoverFilter[] {
    return this.apiDiscoverFiltersSource.getValue();
  }

  public setAPIDiscoverFilters(apiDiscoverFilters: DiscoverFilter[]): void {
    this.apiDiscoverFiltersSource.next(apiDiscoverFilters);
  }

  public getDiscoverFilters$(): Observable<DiscoverFilter[]> {
    const expired = isTimestampExpired(this.discoverFiltersTimestamp, this.DISCOVER_FILTERS_SESSION_EXPIRATION);
    const discoverFilters = !expired ? of(this.getAPIDiscoverFilters()) : this.fetchDiscoverFilters();
    return discoverFilters.pipe(tap((discoverFilters) => this.setAPIDiscoverFilters(discoverFilters)));
  }

  public fetchDiscoverFilters(): Observable<DiscoverFilter[]> {
    const headers = new HttpHeaders({ 'Accept-Version': '2' });
    return this.http.get(`${this.BOX_API}/discover-filters`, { headers }).pipe(
      tap(() => (this.discoverFiltersTimestamp = dayjs().unix())),
      map((response: APIResponse<{ discoverFilters: DiscoverFilter[] }>) => response.payload.discoverFilters),
      catchError((error: APIError) => {
        this.sentryService.captureException(error);
        return of(defaultDiscoverFilters as DiscoverFilter[]);
      }),
      map((discoverFilters) => discoverFilters?.sort((a, b) => a.position - b.position))
    );
  }

  public init(shops: Shop[], activeFilterSlugs: string[] = []): void {
    /** Discover Filters appear only on food shops. Therefore, in order tp decorate or filter
     * the Discover Filters we need to limit the shops to Food vertical Shops only */
    const foodShops = shops.filter((shop) => shop.businessVertical === 'food');
    const discoverFilters = this.decorateDiscoverFilters(foodShops, activeFilterSlugs);
    const userSegments = this.userService.getUser()?.segments ?? [];
    const filteredDiscoverFilters = filterEnabledItems(discoverFilters, userSegments);
    this.setDiscoverFilters(filteredDiscoverFilters);
  }

  public toggleDiscoverFilter(discoverFilter: DiscoverFilter): void {
    const currentDiscoverFilters = this.discoverFiltersSource.getValue();
    const currentDiscoverFilter = currentDiscoverFilters.find((c) => c._id === discoverFilter._id);
    currentDiscoverFilter.active = !currentDiscoverFilter.active;
    this.setDiscoverFilters(currentDiscoverFilters);
  }

  public getMainViewDiscoverFilters(discoverFilters: DiscoverFilter[], shops: Shop[]): DiscoverFilter[] {
    const numberOfFilters = this.configService.getConfiguration()?.numberOfMainViewFilters;
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    const orders = this.ordersService.getOrderHistory();
    const filtersWithUpdatedPositions = discoverFilters
      .filter((filter) => !!filter.visibleInMainView)
      .filter((filter) => filterHasShopResults(filter, shops, { cuisines, orders, chains }))
      .map((discoverFilter) => {
        const defaultPosition = discoverFilter?.mainViewDefaultPosition ? discoverFilter.mainViewDefaultPosition : 0;
        discoverFilter.mainViewDefaultPosition =
          getMainViewTileCurrentPosition(discoverFilter.mainViewSortingPerDay) ?? defaultPosition;
        return discoverFilter;
      });
    const sortedFilters = filtersWithUpdatedPositions.sort(
      (a, b) => a.mainViewDefaultPosition - b.mainViewDefaultPosition
    );
    return numberOfFilters ? sortedFilters.splice(0, numberOfFilters) : sortedFilters;
  }

  public appendDiscoverFiltersQueryString(vertical: BusinessVertical, queryParams: URLSearchParams): void {
    if (vertical !== 'food') return;
    const currentFilters = this.discoverFiltersSource.getValue();
    const selectedFilters = currentFilters.filter((filter) => filter.active);
    if (selectedFilters.length === 0) return;
    queryParams.set('filters', selectedFilters.map((filter) => filter.slug).join(','));
  }

  private decorateDiscoverFilters(shops: Shop[], activeFilterSlugs: string[] = []): DiscoverFilter[] {
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    const orders = this.ordersService.getOrderHistory();
    const apiDiscoverFilters = this.apiDiscoverFiltersSource.getValue();
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const decoratedFilters = apiDiscoverFilters.map((filter) => {
      const shopsCount = filterShopsByDiscoverFilters(shops, [filter], { cuisines, orders, chains }).length;
      const enabled = shopsCount > 0;
      const multiplier = getDiscoverFilterMultiplier(filter, promoCampaigns);
      const position = enabled ? filter.position : 100;
      const active = activeFilterSlugs.includes(filter.slug);
      const dishRelated = isFilterDishRelated(filter, promoCampaigns);
      return { ...filter, shopsCount, enabled, position, active, multiplier, dishRelated };
    });
    return orderBy(decoratedFilters, ['enabled', 'position'], ['desc', 'asc']);
  }
}
