import { Injectable } from '@angular/core';
import { getHtmlFromEditorData } from '@box/utils';
import { PromoBanner } from '@box-types';

@Injectable()
export class PromoBannerDetailsDialogService {
  public getPromoBannerBlocksHtml(promoBanner: PromoBanner): string {
    if (!promoBanner?.detailsDescription?.blocks?.length) return '';
    return getHtmlFromEditorData(promoBanner.detailsDescription);
  }
}
