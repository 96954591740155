import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'image-tile',
  templateUrl: './image-tile.component.html',
  styleUrls: ['./image-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageTileComponent implements OnChanges {
  @Input() public overlayText: string;
  @Input() public checked: boolean;
  @Input() public imgUrl: string;
  @Input() public altText: string;
  @Input() public disabled: boolean;
  @HostBinding('class') public hostClass = 'image-tile';
  @HostBinding('class.image-tile-disabled') public hostDisabled: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue as boolean;
      this.hostDisabled = this.disabled;
    }
  }
}
