import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { Location, Shop, APIResponse } from '@box-types';
import { normalizeLocation, filterValidLocations } from '@box/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LocationsService {
  private BOX_API: string = environment.application.API_URL;

  constructor(private http: HttpClient) {}

  public fetchLocation(locationKey: string): Observable<Location> {
    const params: HttpParams = new HttpParams().set('key', locationKey);
    return this.http
      .get(`${this.BOX_API}/location/getOne`, { params })
      .pipe(map((response: APIResponse<{ location: Location }>) => response.payload.location));
  }

  public fetchLocations(): Observable<Location[]> {
    return this.http.get(`${this.BOX_API}/location/getAll`).pipe(
      map((response: APIResponse<{ locations: Location[] }>) => {
        const locations = response.payload.locations;
        const filteredLocations = filterValidLocations(locations) as Location[];
        return filteredLocations.map((location) => normalizeLocation(location));
      })
    );
  }

  public fetchLocationsByPostalCode(postalCode: string): Observable<Location[]> {
    const params = new HttpParams().set('postalCode', postalCode);
    return this.http.get(`${this.BOX_API}/location/findByPostalCode`, { params }).pipe(
      map((response: APIResponse<{ locations: Location[] }>) => {
        const locations = response.payload.locations;
        const filteredLocations = filterValidLocations(locations) as Location[];
        return filteredLocations.map((location) => normalizeLocation(location));
      })
    );
  }

  public fetchShopsByLocation(locationKey: string): Observable<Shop[]> {
    const params: HttpParams = new HttpParams().set('key', locationKey);
    return this.http
      .get(`${this.BOX_API}/shops/getShopsByLocation`, { params })
      .pipe(map((response: APIResponse<Shop[]>) => response.payload));
  }

  public fetchShopsByCuisineAndLocation(locationKey: string, cuisineKey: string): Observable<Shop[]> {
    const params: HttpParams = new HttpParams().set('location', locationKey).set('cuisine', cuisineKey);
    return this.http
      .get(`${this.BOX_API}/shops/findShopsByCuisineAndLocation`, { params })
      .pipe(map((response: APIResponse<{ shops: Shop[] }>) => response.payload.shops));
  }
}
