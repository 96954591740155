import { animate, group, state, style, transition, trigger } from '@angular/animations';

export const NotificationAnimation = trigger('notificationAnimation', [
  state('void', style({ opacity: 0, transform: 'scale(0.8)' })),
  transition('void => *', [
    group([
      animate('45ms cubic-bezier(0, 0, 0.2, 1)'),
      style({ opacity: 1 }),
      animate('105ms cubic-bezier(0, 0, 0.2, 1)'),
      style({ transform: 'scale(1)' })
    ])
  ]),
  transition('* => void', [animate('105ms cubic-bezier(0.0, 0, 0.2, 1)'), style({ opacity: 0 })])
]);
