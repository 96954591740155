import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { AnalyticsService, DialogService, PurchaseEventService } from '@box-core/services';
import { Shop, GAClickInfoConfig, GAPromotionConfig } from '@box-types';
import {
  normalizeWebAction,
  ShopTileViewType,
  getCollectionHomeShopsImpressionGAConfig,
  HomeSection,
  showHomeSectionInfo
} from '@box/utils';
import { Mousewheel, Navigation } from 'swiper';
import { SwiperOptions } from 'swiper/types';
import { HomeSectionInfoDialogComponent } from '../home-section-info-dialog/home-section-info-dialog.component';

@Component({
  selector: 'home-shops-section',
  templateUrl: './home-shops-section.component.html',
  styleUrls: ['./home-shops-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeShopsSectionComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;
  @ViewChild('navButtonContainer', { static: true }) private navButtonContainer: ElementRef<HTMLDivElement>;

  @Input() private homeSection: HomeSection;

  public shops: Shop[];
  public title: string;
  public buttonText: string;
  public showActionButton: boolean;
  public swiperOptions: SwiperOptions;
  public shopViewType: ShopTileViewType;
  public showInfo: boolean;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 0.8;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private dialogService: DialogService,
    private analyticsService: AnalyticsService,
    private purchaseEventService: PurchaseEventService
  ) {}

  @HostBinding('class') public hostClass = 'home-shops-section';

  ngOnInit(): void {
    this.setSwiperOptions();
  }

  ngAfterViewInit(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const buttonContainer: HTMLDivElement = this.navButtonContainer.nativeElement;
    const nextButtonIsDisabled = nextEl.classList.contains('swiper-button-disabled');
    const prevButtonIsDisabled = prevEl.classList.contains('swiper-button-disabled');
    if (nextButtonIsDisabled && prevButtonIsDisabled) {
      this.renderer.addClass(buttonContainer, 'hidden');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.homeSection = changes.homeSection.currentValue as HomeSection;
    this.title = this.homeSection.title;
    this.showActionButton = this.homeSection.showActionButton;
    this.shops = this.homeSection.filteredShops;
    this.buttonText = this.homeSection.buttonTitle;
    this.shopViewType = this.homeSection.shopViewType;
    this.showInfo = showHomeSectionInfo(this.homeSection);
  }

  public onOpenInfo(): void {
    this.triggerClickInfoAnalyticsEvent();
    const dialogConfig = { data: { homeSection: this.homeSection } };
    this.dialogService.openDialog(HomeSectionInfoDialogComponent, dialogConfig);
  }

  public onAction(): void {
    const webAction = this.homeSection?.webAction;
    if (!webAction) return void this.router.navigateByUrl('/discover');
    const navigationUrl = normalizeWebAction(webAction);
    return void this.router.navigateByUrl(navigationUrl);
  }

  public onShopEnteredViewport(shop: Shop): void {
    this.triggerAnalyticsViewPromoEvent(shop);
  }

  public onShopCardClick(shop: Shop): void {
    this.triggerAnalyticsSelectPromoEvent(shop);
  }

  private setSwiperOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Navigation, Mousewheel],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 8
    };
  }

  private triggerClickInfoAnalyticsEvent(): void {
    const gaConfig = {
      creative_name: this.homeSection.slug,
      creative_slot: 'discover_home',
      promotion_id: this.homeSection.slug,
      promotion_name: this.homeSection.title
    } as GAClickInfoConfig;
    this.analyticsService.addGACustomEvent('click_info', gaConfig);
  }

  private triggerAnalyticsViewPromoEvent(shop: Shop): void {
    const gaConfig = getCollectionHomeShopsImpressionGAConfig(this.homeSection, this.shops, shop);
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerAnalyticsSelectPromoEvent(shop: Shop): void {
    const gaConfig = getCollectionHomeShopsImpressionGAConfig(this.homeSection, this.shops, shop);
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
    this.updatePurchaseEvent(shop, gaConfig);
  }

  private updatePurchaseEvent(shop: Shop, gaConfig: GAPromotionConfig) {
    if (!gaConfig?.items?.length) return;
    const shopIndex = gaConfig['items'][0].index;
    const purchaseEvent = {
      creativeName: '',
      index: shopIndex,
      itemListName: this.homeSection.slug,
      creativeSlot: 'discover_home',
      promotionName: this.homeSection.title
    };
    this.purchaseEventService.updatePurchaseEvent(shop._id, purchaseEvent);
  }
}
