import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { Address, APIError, APIResponse, EditorData, FetchShopsOptions, PromoPageData, Shop } from '@box-types';
import { catchError, map, Observable, of } from 'rxjs';
import {
  filterItemShops,
  getOutputBlockHtml,
  getPromoPageHeroBackgroundImageUrl,
  normalizeHTMLAnchor,
  normalizeShop,
  sortShops
} from '@box/utils';
import {
  CoreService,
  OrdersService,
  ShopsService,
  TimeslotsService,
  AddressesService,
  UserService,
  SentryService
} from '@box-core/services';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PromoPagesService {
  private BOX_API: string = environment.application.API_URL;
  private readonly defaultAddress: Address = { longitude: 23.7275388, latitude: 37.9838096, postalCode: '10560' };

  constructor(
    private http: HttpClient,
    private coreService: CoreService,
    private ordersService: OrdersService,
    private shopsService: ShopsService,
    private timeslotsService: TimeslotsService,
    private addressesService: AddressesService,
    private userService: UserService,
    private sentryService: SentryService,
    private router: Router
  ) {}

  public fetchPromoPage$(slug: string): Observable<PromoPageData> {
    return this.http.get(`${this.BOX_API}/landing-pages/${slug}`).pipe(
      map((response: APIResponse<{ landingPage: PromoPageData }>) => {
        const landingPage = response.payload?.landingPage;
        if (!landingPage) return;
        return landingPage;
      }),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Delivery',
          domainDetails: 'Fetch Promo Page',
          severity: 'error'
        });
        return of(null);
      })
    );
  }

  public fetchShops$(): Observable<Shop[]> {
    const fetchShopsOptions: FetchShopsOptions = this.getFetchShopsOptions();
    return this.shopsService.fetchShops(fetchShopsOptions).pipe(
      map((shops) => {
        const normalizedShops = shops.map((shop) => normalizeShop(shop));
        const timeslot = this.timeslotsService.getTimeslot();
        return sortShops(normalizedShops, timeslot);
      })
    );
  }

  public decoratePromoPageWithFilteredShops(promoPage: PromoPageData): PromoPageData {
    const shops = this.shopsService.shops.getValue();
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    const orders = this.ordersService.getOrderHistory();
    const openShops = shops.filter((s) => s.operatingState === 'OPEN');
    const relatedShops = filterItemShops(promoPage, openShops, { cuisines, orders, chains });
    return { ...promoPage, relatedShops };
  }

  public getPromoPageBlocksHtml(text: EditorData): string {
    if (!text?.blocks?.length) return;
    return text.blocks.map((block) => normalizeHTMLAnchor(getOutputBlockHtml(block))).join('');
  }

  public getPromoPageHeroBackgroundImageUrl(promoPageData: PromoPageData): string {
    const url = getPromoPageHeroBackgroundImageUrl(promoPageData);
    if (url) return `url('${url}')`;
  }

  public handlePromoPageError(error: Error | APIError): void {
    this.sentryService.captureException(error, {
      domain: 'Delivery',
      domainDetails: 'Promo Page',
      severity: 'error'
    });
    return void this.router.navigate([]);
  }

  private getFetchShopsOptions(): FetchShopsOptions {
    const address = this.addressesService.getAddress();
    const guid = this.userService.getUser()?.guid;
    if (!address) return { guid, address: this.defaultAddress };
    return { guid, address };
  }
}
