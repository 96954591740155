import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  DialogService,
  ShopService,
  PaymentTypesService,
  CouponsService,
  SentryService,
  UserService,
  LoaderService,
  CartService,
  translate
} from '@box-core/services';
import { BoxConfirmDialogComponent } from '@box-shared/components';
import {
  getDiscountResultMessage,
  getDiscountWarningMessage,
  isCouponDisabled,
  isCouponDummy,
  isOrderCoupon,
  isCouponRedeemamble,
  isCouponSelectable,
  getCouponBenefitText,
  getPizzaFanCouponDescription,
  isCouponRelatedToSynergy,
  sortAvailableCoupons,
  getOffersSelectedProducts
} from '@box/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CheckoutCouponsDialogResponse } from '@box-checkout/components/checkout-coupons-dialog/checkout-coupons-dialog.interface';
import { map, tap, catchError, finalize } from 'rxjs/operators';
import { CheckoutCouponsDialogComponent } from '@box-checkout/components';
import { APIError, Coupon, FetchCouponsForCheckoutOptions, CouponProduct, InfoDialogResponse } from '@box-types';
import { CouponRedemptionDialogComponent } from '@box-coupon-widget/components';
import { CheckoutStateService } from '@box-checkout/services/checkout-state.service';
import { currencyCode } from '@box-core/services/currency.service';

const PAYMENT_TYPE_ERROR_DATA = {
  title: 'coupon_redemption',
  messages: ['you_must_select_payment_by_card_to_complete_a_discount_coupon']
};

export const DFY_TYPE_ERROR_DATA = {
  title: 'do_not_forget',
  messages: ['you_must_enter_a_valid_dfy_code', 'please_type_your_code_in_coupon_field']
};

@Injectable()
export class CheckoutCouponsService {
  private readonly couponSource = new BehaviorSubject<Coupon>(undefined);
  public readonly coupon$ = this.couponSource.asObservable();

  private readonly couponsSource = new BehaviorSubject<Coupon[]>([]);
  public readonly coupons$ = this.couponsSource.asObservable();

  constructor(
    private cartService: CartService,
    private dialogService: DialogService,
    private shopService: ShopService,
    private paymentTypesService: PaymentTypesService,
    private couponsService: CouponsService,
    private sentryService: SentryService,
    private userService: UserService,
    private loaderService: LoaderService,
    private checkoutStateService: CheckoutStateService
  ) {}

  public getCoupon(): Coupon {
    return this.couponSource.getValue();
  }

  public setCoupon(coupon: Coupon): void {
    this.couponSource.next(coupon);
  }

  public clearCoupon(): void {
    this.couponSource.next(undefined);
  }

  public clearCoupons(): void {
    this.couponsSource.next([]);
  }

  public getCoupons(): Coupon[] {
    return this.couponsSource.getValue();
  }

  public setCoupons(coupons: Coupon[]): void {
    this.couponsSource.next(coupons);
  }

  public updateCheckoutCoupons(): Observable<Coupon[]> {
    const collectionType = this.shopService.getShop().collectionType;
    const fetchCouponsOptions: FetchCouponsForCheckoutOptions = {
      items: this.getOrderItems(),
      itemsTotalPrice: this.cartService.getCart().itemsFinalPrice,
      paymentMethod: this.paymentTypesService.getPaymentType().type,
      redeemPoints: Boolean(this.checkoutStateService.getPointsDiscount())
    };

    return this.couponsService.fetchCheckoutCoupons(collectionType, fetchCouponsOptions).pipe(
      map((coupons) => sortAvailableCoupons(coupons)),
      tap((coupons) => this.setCoupons(coupons)),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Coupons',
          domainDetails: 'Checkout Coupons Available',
          severity: 'error'
        });
        this.clearCoupons();
        return of([]);
      })
    );
  }

  public findCouponAfterUpdate(coupon: Coupon = this.getCoupon()): Coupon {
    if (!coupon?.code && !coupon?.triggerPromoInitiation?.name) return;
    const updatedCoupon = (this.getCoupons() ?? []).find((newCoupon) => {
      if (coupon.code) {
        return newCoupon.code === coupon.code;
      } else if (coupon.triggerPromoInitiation?.name) {
        // for dummy coupons
        return newCoupon.triggerPromoInitiation?.name === coupon.triggerPromoInitiation.name;
      }
    });

    this.showErrorMessagesAfterCouponUpdate(updatedCoupon);
    return updatedCoupon;
  }

  public invalidDFY(): boolean {
    const hasDFY: boolean = this.shopService.cartHasDFYOffer();
    const couponType: string = this.getCoupon()?.type;
    const hasValidDFYCoupon: boolean = couponType === 'dfu' || couponType === 'unlockoffer';
    return hasDFY && !hasValidDFYCoupon;
  }

  public showErrorMessagesAfterCouponUpdate(newCoupon: Coupon): void {
    if (!newCoupon) {
      return void this.dialogService.openInfoDialog({
        title: 'something_went_wrong',
        messages: ['the_coupon_does_not_meet_all_redemption_criteria_on_this_order']
      });
    }
    if (isCouponDisabled(newCoupon)) {
      return void this.dialogService.openInfoDialog({
        title: 'something_went_wrong',
        messages: [newCoupon.disabledText, 'thats_why_your_voucher_cannot_be_activated']
      });
    }
  }

  public getOrderItems(): CouponProduct[] {
    const { offers, products } = this.cartService.getCart();
    const offerProducts = getOffersSelectedProducts(offers);

    const productOrderItems: CouponProduct[] = (products ?? []).map((product) => ({
      id: product._id,
      numberOfUnits: product.cartQuantity ?? 1
    }));
    const offerOrderItems: CouponProduct[] = (offerProducts ?? []).map((op) => ({
      id: op.productId,
      numberOfUnits: op.quantity ?? 1
    }));
    return [...productOrderItems, ...offerOrderItems];
  }

  public showCouponDiscountRemovalDialog(
    coupon: Coupon,
    discount: number,
    cartPrice: number
  ): MatDialogRef<BoxConfirmDialogComponent> {
    const discountWarningMessage = getDiscountWarningMessage(coupon, discount, cartPrice, translate, currencyCode);
    const discountResultMessage = getDiscountResultMessage(coupon, cartPrice, translate, currencyCode);
    const defaultMessage = 'coupon_discount_will_be_removed_from_your_cart';
    return this.dialogService.openConfirmDialog({
      title: 'dont_forget',
      messages: [discountWarningMessage, discountResultMessage, defaultMessage],
      confirmText: 'confirm_',
      cancelText: 'back_'
    });
  }

  public getCouponDescription(startingPrice: number): string {
    if (this.shopService.cartHasDFYOffer()) return getPizzaFanCouponDescription(this.getCoupon(), translate);
    return this.getBOXCouponDescription(startingPrice);
  }

  private getBOXCouponDescription(startingPrice: number): string {
    const coupon = this.getCoupon();
    if (!coupon) {
      const isSuperMarket = this.shopService.getShop()?.isSuperMarket;
      if (isSuperMarket) return 'redeem_your_coupon_box_or_other';
      return 'redeem_your_coupon_dfy_or_other';
    }

    if (coupon.info?.alternativeText) return coupon.info.alternativeText;

    const benefitText = getCouponBenefitText(coupon, startingPrice, translate, currencyCode);
    const isDummy = isCouponDummy(coupon);
    if (isDummy) return translate('earn_discount_benefits', { _BENEFITS_TEXT: benefitText });
    const couponCode = coupon.code ? ` ${coupon.code} ` : ' ';
    return translate('redeem_coupon_and_earn_benefits', {
      _COUPON_CODE: couponCode,
      _BENEFITS_TEXT: benefitText
    });
  }

  public openCouponsDialog(): Observable<CheckoutCouponsDialogResponse> {
    return this.dialogService
      .openDialog(CheckoutCouponsDialogComponent, {
        panelClass: 'box-dialog-fit-content'
      })
      .afterClosed()
      .pipe(map((data) => data as CheckoutCouponsDialogResponse));
  }

  private redeemCoupon(code: string): Observable<Coupon> {
    if (!code?.length) return;
    this.loaderService.setState(true);
    return this.couponsService.redeemCoupon(code).pipe(
      finalize(() => this.loaderService.setState(false)),
      catchError((error: APIError) => {
        this.dialogService.openErrorDialog(error);
        return of(null);
      })
    );
  }

  public showCouponRedemptionSuccessDialog(coupon: Coupon): Observable<unknown> {
    return this.dialogService
      .openDialog(CouponRedemptionDialogComponent, {
        panelClass: 'box-dialog-fit-content',
        data: { coupon }
      })
      .afterClosed();
  }

  public initiateRedemptionFlow(coupon: Coupon): void {
    this.redeemCoupon(coupon.code).subscribe((coupon) => {
      if (!coupon) return;
      this.showCouponRedemptionSuccessDialog(coupon).subscribe(() => {
        if (coupon.benefitType === 'LOYALTY_POINTS') this.userService.addPoints(coupon.loyaltyPoints);
      });
    });
  }

  public showPaymentErrorDialog(): Observable<InfoDialogResponse> {
    return this.dialogService.openInfoDialog(PAYMENT_TYPE_ERROR_DATA).afterClosed();
  }

  public isPaymentTypeValid(): boolean {
    const paymentType = this.paymentTypesService.getPaymentType().type;
    return !['cash', 'empty'].includes(paymentType);
  }

  public selectCoupon(coupon: Coupon): void {
    if (!isCouponSelectable(coupon)) return;
    if (this.invalidDFY()) return void this.dialogService.openInfoDialog(DFY_TYPE_ERROR_DATA);
    if (!isCouponDummy(coupon) && coupon?.code === this.getCoupon()?.code) return this.clearCoupon();
    if (isCouponRedeemamble(coupon)) return this.initiateRedemptionFlow(coupon);
    if (isOrderCoupon(coupon)) this.setCoupon(coupon);
  }

  public isExploreCouponSelected(): boolean {
    const selectedCoupon = this.couponSource.getValue();
    if (!selectedCoupon) return false;
    return isCouponRelatedToSynergy(selectedCoupon, 'EXPLORE-30-COUPON-PROMO');
  }
}
