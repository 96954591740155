import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NotificationConfig, NotificationRef } from '@box-core/components';
import { InfoNotificationWidgetData } from './info-notification-wrapper.types';

@Component({
  selector: 'info-notification-wrapper',
  templateUrl: './info-notification-wrapper.component.html',
  styleUrls: ['./info-notification-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoNotificationWrapperComponent implements OnInit {
  public matIcon: string;
  public title: string;
  public message: string;

  constructor(private notificationConfig: NotificationConfig, private notificationRef: NotificationRef) {}

  ngOnInit(): void {
    const data = this.notificationConfig.data as InfoNotificationWidgetData;
    this.matIcon = data.matIcon;
    this.title = data.title;
    this.message = data.message;
  }

  public onClose(): void {
    this.notificationRef.dismiss();
  }
}
